import React from "react";

type Error = { message: string };

const Error = ({ message }: Error) => (
  <span className="text-theme-color-error text-sm w-full -mt-1 mb-2 block">
    <span className="sr-only">Error: </span>
    {message}
  </span>
);

type LabelProps = React.ComponentPropsWithoutRef<"label">;

const Label = ({ children, ...props }: LabelProps) => (
  <label {...props} className="text-base text-gray-700 inline-block mb-2">
    {children}
  </label>
);

type HelpTextProps = { helpText: string; helpLabel?: string };

const HelpText = ({ helpText, helpLabel }: HelpTextProps) => {
  const TooltipClasses = `
    text-sm text-gray-400 
    absolute right-0 bottom-full 
    py-2 px-4 w-48 
    bg-white rounded shadow-lg
    transition-all duration-500 
    opacity-0 group-hover:opacity-100
    invisible group-hover:visible 
  `.trim();

  return (
    <div className="group relative">
      <p className="cursor-pointer hover:underline text-gray-400 text-sm mb-2">
        {helpLabel ? helpLabel : "Why we ask"}
      </p>
      <p className={TooltipClasses}>{helpText}</p>
    </div>
  );
};

interface FormFieldProps extends React.ComponentPropsWithoutRef<"label"> {
  id: string;
  role?: string;
  hint?: string;
  label?: string;
  helpText?: string;
  helpLabel?: string;
  contained?: boolean;
  relative?: boolean;
  error?: Error;
  children: React.ReactNode;
}

const FormField = ({
  id,
  role,
  hint,
  label,
  error,
  htmlFor,
  helpText,
  helpLabel,
  children,
  relative = true,
}: FormFieldProps) => (
  <div
    role={role}
    data-testid={error && "error"}
    className={`w-full ${relative ? "relative" : ""} ${
      error ? "form-error" : ""
    }`}
  >
    {helpText ? (
      <div className="flex items-baseline justify-between">
        {label && (
          <Label id={id} htmlFor={htmlFor}>
            {label}
          </Label>
        )}
        <HelpText helpText={helpText} helpLabel={helpLabel} />
      </div>
    ) : (
      label && (
        <Label id={id} htmlFor={htmlFor}>
          {label}
        </Label>
      )
    )}

    {children}

    {error && <Error message={error.message} />}

    {hint && <div className="text-sm mb-2 text-gray-600">{hint}</div>}
  </div>
);

FormField.Error = Error;

export { FormField as default, Error, Label, HelpText };
